.white-footer{
    margin: 5rem;
}
.white-footer p{
    font-size: 2rem;
    text-align: center;
}
.white-footer a{
    color: #ED5B00;
    text-decoration: none;
    font-size: 2rem;
}

@media screen and (max-width: 768px) {
    .white-footer{
        margin: 2rem;
    }
    .white-footer p{
        font-size: 1rem;
    }
    .white-footer a{
        font-size: 1rem;
    }
}