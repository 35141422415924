.NavBtn {
  top: 30px;
  right: 90px;
  z-index: 2;
  position: fixed;
  z-index: 999;
}

.NavBtn:hover {
  cursor: pointer;
}
.nav-close-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* width: 300px; */
}
.nav-close-btn {
  height: 60px;
  width: 60px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #ed5b00;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.nav-open-btn {
  height: 60px;
  width: 60px;
  background-color: #ed5b00;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #ed5b00;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.navbar-component {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 100;
  animation: fadeIn 0.5s ease-in-out; */
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 100vh;
  background-color: #f2f2f2;
  z-index: 100;
  animation: expand 0.5s ease-in-out forwards;
}

.navbar-component.closing {
  animation: collapse 0.5s ease-in-out forwards;
}

.navbar-content {
  margin-left: 5rem;
  margin-right: 5rem;
  /* margin: 5rem 5rem 5rem 5rem; */
  display: flex;
  height: 100vh;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5rem;
}
.contact-us-content {
  margin-left: 5rem;
  margin-right: 5rem;
  /* margin: 5rem 5rem 5rem 5rem; */
  display: flex;
  height: 100vh;
  padding-bottom: 5rem;
  justify-content: space-between;
  align-items: flex-end;
}

.navbarImg-container {
  width: 500px;
}
.navbarImg-container img {
  height: 100%;
  width: 100%;
}

.navbar-menu {
  /* margin-top: 10rem; */
  text-align: end;
}
.contact-us-menu {
  margin-top: 8rem;
  text-align: left;
  width: 40%;
}
.menu-text-container {
  margin-top: 1rem;
}
.menu-text-container a {
  position: relative;
  font-size: 2rem;
  text-decoration: none;
  color: #0b0b0b;
}
.menu-text-container a {
  background-image: linear-gradient(to right, #ed5b00, #ed5b00 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

/* .hr-container{
  margin-top: 3rem;
} */
.hr-container {
  margin-top: 3rem;
  position: relative;
  border: none;
  height: 1px;
  background-color: #ccc;
}
/* .hr-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: orange;
  transition: width 0.3s ease-in-out;
}
.hr-container:hover::before {
  width: 100%;
}
.hr-container:hover {
  height: 2px;
} */

.social-links {
  margin-right: 2rem;
}
.social-links img {
  margin-top: 2rem;
  height: 25px;
  width: auto;
}
.menu-text-container a:hover {
  background-position: 0;
}

.menu-text-container a:hover::before {
  width: 100%;
}

.contact-us-title p {
  font-weight: 700;
  font-size: 2.5rem;
  position: absolute;
  top: 50px;
}

.contact-email:hover .title-text {
  /* cursor: pointer; */
  /* color: #ed5b00; */
  background-image: linear-gradient(to left, #ed5b00, #ed5b00 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  /* padding: 5px 0; */
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}
.contact-whatsapp:hover .title-text {
  /* cursor: pointer; */
  /* color: #ed5b00; */
  background-image: linear-gradient(to left, #ed5b00, #ed5b00 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  /* padding: 5px 0; */
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.contact-social:hover .title-text {
  /* cursor: pointer; */
  /* color: #ed5b00; */
  background-image: linear-gradient(to left, #ed5b00, #ed5b00 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  /* padding: 5px 0; */
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}
.contact-us-menu-container .title-text {
  font-weight: 600;
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
}

.contact-us-menu-container .des-text a {
  text-decoration: none;
  font-weight: 300;
  font-size: 1.2rem;
  cursor: pointer;
  color: #0b0b0b;
}

.menu-text-container {
  opacity: 0;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.menu-text-container a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #ed5b00;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.menu-text-container a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
.menu-text-container a:hover {
  color: #ed5b00;
}
.hide {
  display: none;
}
/* .hide-img{
  animation: fadeOut 0.5s ease-out;
} */

.fade-in {
  animation-name: fadeIn;
}

.fade-out {
  animation-name: fadeOut;
}
.scale-in-tl {
  -webkit-animation: scale-in-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-tl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
.tracking-in-expand {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
.tracking-out-contract {
  -webkit-animation: tracking-out-contract 0.7s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: tracking-out-contract 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
}

@-webkit-keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}
@keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1024px) {
  .menu-text-container a {
    font-size: 2rem;
  }
  .navbar-menu {
    text-align: right;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .NavBtn {
    top: 30px;
    right: 20px;
    
  }
  .nav-close-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    /* width: 300px; */
  }
  .nav-close-btn img {
    height: 15px !important;
    width: auto;
  }
  .nav-open-btn img {
    height: 15px !important;
    width: auto;
  }
  .nav-close-btn {
    height: 40px;
    width: 40px;
  }
  .nav-open-btn {
    height: 40px;
    width: 40px;
   
  }
  .hr-container {
    margin-top: 1.5rem;
    position: relative;
    border: none;
    height: 1px;
    background-color: #ccc;
  }
  .navbar-menu {
    margin-top: 2rem;
    margin-right: 5rem;
  }
  .navbar-content {
    margin-left: 1rem;
    flex-direction: column;
    margin-right: 1rem;
    align-items: center;
    display: flex;
    text-align: right;
    height: 100%;
    justify-content: center;
    /* align-items: flex-end; */
    padding-bottom: 1rem;
  }
  .navbarImg-container {
    /* margin-bottom: 5rem; */
    margin-top: 1rem;
    width: 400px;
  }
  .NavBtn img {
    top: 30px;
    right: 20px;
    height: 60px;
    width: auto;
  }
  .contact-us-content {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 100%;
    padding-bottom: 5rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .contact-us-menu {
    margin-top: 0rem;
    text-align: left;
    width: 90%;
    /* margin-left: 10rem;
    margin-right: 10rem; */
  }
  .scale-in-tl {
    height: 15px;
    width: auto;
  }
  .contact-us-title p {
    display: none;
  }
  .contact-us-menu-container .title-text {
    margin-top: 1rem;
    font-size: 1.2rem;
    display: flex;
  }

  .contact-us-menu-container .des-text a {
    text-decoration: none;
    font-weight: 300;
    font-size: 1.2rem;
    cursor: pointer;
    color: #0b0b0b;
  }
}

@media screen and (max-width: 400px) {
  .navbarImg-container {
    /* margin-bottom: 5rem; */
    margin-top: 5rem;
    width: 200px;
  }
  .scale-in-tl {
    height: 10px;
    width: auto;
  }
}
.tracking-in-contract-bck {
	-webkit-animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

 @-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

@keyframes collapse {
  from {
    width: 100vw;
  }
  to {
    width: 0;
  }
}

@keyframes expand {
  from {
    width: 0;
  }
  to {
    width: 100vw;
  }
}
