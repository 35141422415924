.case-study-pages-container {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;
    font-family: 'Eudoxus Sans', sans-serif;
}

.case-study-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.case-title-text {
    font-size: 50px;
    color: #ed5b00;
    align-self: flex-start;

    padding: 0.5rem 0 0 0.5rem;
    animation: slide 0.5s ease forwards;
}

.case-subtitle-text {
    font-size: 25px;
    align-self: flex-start;

    padding: 0rem 0.5rem;
    animation: slide 0.5s ease forwards;
}

.case-study-container {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 2rem;
    padding: 1rem;
    margin-top: 1rem;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .case-study-container {
        grid-template-columns: 1fr;
    }
}

.case-study-footer {
    width: 100%;
    height: 100%;
}