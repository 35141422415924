.BlackFooter {
  background-color: #001240;
  color: white;
  font-family: "Eudoxus Sans", sans-serif;
  padding: 2rem;
  text-align: center;
}
.BlackFooter p{
    font-size: 1.2rem;
}
.BlackFooter a{
    color: #ED5B00;
    text-decoration: none;
    font-size: 1.2rem;
}
.underline-text{
    text-decoration: underline;
    cursor: pointer;
    color: white;
    font-size: 15px !important;

    text-align: center;
}

.footer-social-links-container {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 1rem;
    margin-top: 1rem;
}

.footer-social-links img {
    height: 25px;
    width: auto;
}

.footer-instagram, .footer-linkedin, .footer-facebook {
    /* Change the color */
    filter: invert(1);
}

.rights-text {
    font-size: 16px !important;
}

.footer-links-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 5px;
}

@media screen and (max-width: 768px) {
    .BlackFooter{
        padding: 1rem;
    }
    .BlackFooter p{
        font-size: 0.8rem;
    }
    .BlackFooter a{
        font-size: 0.8rem;
    }
    .underline-text{
        font-size: 15px !important;
    }
}