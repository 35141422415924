.case-study-card {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: 'Eudoxus Sans', sans-serif;
    padding: 0 0 1rem 0;
    border: 1px solid #8e8883;
    width: 100%;
    padding: 2rem;
    cursor: pointer;
    position: relative;
    --triangle-color: #ed5b00;
    transition: background-color 0.3s, color 0.3s;
}

.case-study-card::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-top: 10px solid var(--triangle-color);
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--triangle-color);
    transform: rotate(270deg);
}

.case-study-card:hover {
    background-color: #ed5b00;
    color: white;
    --triangle-color: white;

    .card-center-line {
        animation: moveDown 0.3s forwards;
        background-color: white;
    }

    .card-title-text {
        animation: moveDown 0.3s forwards;
    }
}

.card-title-text {
    font-weight: bold;
}

.card-center-line {
    width: 50%;
    height: 3px;
    background-color: #ed5b00;
    margin: 1rem 0;
}

@keyframes moveDown {
    to {
        transform: translateY(10px);
    }
}