.video-navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0.2rem 10rem;
    background-color: white;

    border-bottom: 2px solid #ddd;   
    
    height: fit-content
}

.video-navbar-logo > a > img {
    width: 50px;
    height: 50px;
    cursor: pointer;
}
